import * as React from "react"

import Layout from "../components/layout"
import Inhaltsverzeichnis from "../components/inhaltsverzeichnis.jsx"

import Seo from "../components/seo"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import rare from '../images/hotwheels-rare-logo.png'
import carculture from '../images/car-culture.jpg'
import bluecard from '../images/blue-card.webp'
import Button from '@mui/material/Button';
import { display, grid } from "@mui/system";

const Ratgeber = () => (
  <Layout>
    <Seo title="Hot Wheels Serien Übersicht für Sammler" description={"Eine Übersicht der verschiedenen Serien von Hot Wheels, die beliebt bei Sammler sind."} />
      
      <div className='content'>
      <div className='row'>
        <div className=''>
          <h1 style={{marginBottom: 15}}>Übersicht der Hot Wheels Serien für Sammler</h1>
          <p>Bei Hot Wheels Serien werden die Autos oft in Gruppen aufgeteilt. Diese Gruppen bestehen oft aus rund 5 Modellen und haben ein bestimmtes Thema. <br /> <br /> <strong>Unterschied zwischen Premium und Basic</strong><br />Die Premium Serien unterscheiden sich von der Basic Version meistens durch Gummireifen, besondere Farben, Metall, Tampos (Farbdesign und Logos) und generell bessere Verarbeitung.</p>
          <h2 id="premium" className="border" >Premium Serien</h2>
          <h3 id="redline" className="border" >Redline Serie</h3>
          <p className=''>Die Redline-Serie ist eine der ersten Hot Wheels Serien, die jemals produziert wurden. Sie wurde zwischen 1968 und 1977 hergestellt und war bei Sammlern schon immer sehr beliebt. Die Autos in dieser Serie haben eine charakteristische rote Linie auf den Reifen und sind in der Regel in knalligen Farben erhältlich. Einige der seltensten und teuersten Hot Wheels-Autos stammen aus dieser Serie.</p>
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Liste aller Modelle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='' style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
              <div>Beatnik Bandit</div>
              <div>Brabham Repco F1</div>
              <div>Bye-Focal</div>
              <div>Classic '36 Ford Coupe</div>
              <div>Classic '57 T-Bird</div>
              <div>Classic '32 Ford Vicky</div>
              <div>Classic Nomad</div>
              <div>Custom AMX</div>
              <div>Custom Barracuda</div>
              <div>Custom Camaro</div>
              <div>Custom Charger</div>
              <div>Custom Continental Mark III</div>
              <div>Custom Corvette</div>
              <div>Custom Cougar</div>
              <div>Custom Eldorado</div>
              <div>Custom Firebird</div>
              <div>Custom Fleetside</div>
              <div>Custom Mustang</div>
              <div>Custom T-Bird</div>
              <div>Custom Volkswagen</div>
              <div>Deora</div>
              <div>Dodge Deora Concept</div>
              <div>Ferrari 312P</div>
              <div>Ford J-Car</div>
              <div>Heavy Chevy</div>
              <div>Hot Heap</div>
              <div>Lotus Turbine</div>
              <div>Mantis</div>
              <div>McLaren M6A</div>
              <div>Mercedes-Benz 280SL</div>
              <div>Python</div>
              <div>Silhouette</div>
              <div>Splittin' Image</div>
              <div>Sugar Caddy</div>
              <div>The Demon</div>
              <div>The Hood</div>
              <div>Torero</div>
              <div>Turbofire</div>
              <div>Twin Mill</div>
              <div>Volkswagen Beach Bomb</div>
              <div>Volkswagen Karmann Ghia</div>
              <div>What-4</div>
            </div>
          </AccordionDetails>
        </Accordion>
          <h3 id="collectibles">Collectibles Series</h3>
          <p>Die Hot Wheels Collectibles Serie richtet sich an Sammler. Die Modelle zeichnen sich durch eine hohe Qualität und Detailreichtum aus. Ein komplettes Set aus 3 oder 4 Autos ist oft sehr schwer zu finden. Diese Serie ist also eher etwas für Sammler, die nach einer Herausforderung suchen.</p>
          
          <div className='row'>
            <div className=''>
              <h3 id="carculture">Hot Wheels Car Culture</h3>
              <p>Car Culture ist die bekannteste Premium Serie von Hot Wheels. Die Serie hat jedes Jahr 4 bis 6 Neuerscheinungen mit jeweils rund 5 Modellen, die eine bestimmte Kultur verbindet. 
              <br />Beliebt bei Sammler durch die hohe Qualität der Tampos (Farbdesign und Logos), Box Design und das Gewicht.
              Aufgrund der Popularität von Car Culture hat Hot Wheels die zwei verwandten Serien Team Transport und Fast & Furious rausgebracht.</p>
            </div>
            <div className='' >
              <img src={carculture} className="margin" alt="hot wheels car culture" style={{width: 240, minWidth: 240, marginLeft: 15, objectFit: "contain"}}/>
            </div>
          </div>
          <h3 id="vintage">Vintage Racing-Serie </h3>
          <p>Die Vintage Racing-Serie umfasst Modelle, die von historischen Rennwagen inspiriert sind. Diese Modelle haben oft realistische Lackierungen und Beschriftungen und sind bei Fans von Motorsport und klassischen Autos sehr beliebt.</p>
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Liste aller Modelle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            

            <h4>1. Welle</h4>
            <div className='' style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
            <div>'67 Ford Mustang Coupe</div>
              <div>'70 Ford Mustang Mach 1</div>
              <div>'70 Plymouth Road Runner</div>
              <div>'71 Dodge Demon</div>
              <div>'71 Ford Mustang Mach 1</div>
              <div>'71 Plymouth Duster</div>
              <div>'72 Ford Ranchero</div>
              <div>'73 Ford Falcon XB</div>
              <div>'78 Porsche 935/78</div>
            </div>
           
            <h4>2. Welle</h4>
            <div className='' style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
            <div>'70 Ford Torino</div>
              <div>'68 Chevy Nova</div>
              <div>'71 Ford Mustang Boss 302</div>
              <div>'70 Dodge Charger R/T</div>
              <div>'67 Chevy Camaro</div>
              <div>'69 Ford Torino Talladega</div>
              <div>'70 Plymouth Superbird</div>
              <div>'69 Mercury Cougar Eliminator</div>
            </div>
              
            <h4>3. Welle</h4>
            <div className='' style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
            <div>'62 Chevy Bel Air</div>
              <div>'65 Pontiac GTO</div>
              <div>'70 Chevy Camaro RS</div>
              <div>'70 Ford Mustang Boss 302</div>
              <div>'71 AMC Javelin AMX</div>
              <div>'73 Ford Falcon XB</div>
              <div>'78 Porsche 935/78</div>
            </div>
              
          </AccordionDetails>
          </Accordion>
          <h3 id="fastfurious">Hot Wheels Fast & Furious Premium</h3>
          <p>Die Hot Wheels Fast & Furious Premium ist qualitativ sehr vergleichbar mit der Car Culture Serie. Es werden seit 2019 jedes Jahr rund 4 Gruppen von Autos verkauft. Meistens verbindet die Gruppe von Autos ein bestimmter Film. Wir empfehlen die Fast & Furious Serie jedem Sammler der auf Qualität wert legt oder Fan der Filme ist.</p>
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Liste aller Modelle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='' style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                <div>'70 Dodge Charger R/T</div>
                <div>'70 Ford Escort RS1600</div>
                <div>'70 Plymouth Road Runner</div>
                <div>'71 Plymouth GTX</div>
                <div>'95 Mazda RX-7</div>
                <div>2013 Subaru BRZ (in black)</div>
                <div>'70 Chevrolet Chevelle SS Wagon</div>
                <div>'94 Toyota Supra MK IV (in orange)</div>
                <div>'67 Ford Mustang Fastback (in blue)</div>
                <div>'70 Dodge Charger (in silver)</div>
                <div>Nissan Skyline GT-R (BNR34)</div>
                <div>'70 Ford Escort RS1600 (in blue)</div>
                <div>'96 Nissan 180SX Type X</div>
                <div>'02 Nissan Skyline GT-R (R34)</div>
                <div>'70 Chevelle SS Wagon (in blue)</div>
                <div>'70 Ford Escort RS1600 (in red)</div>
                <div>'67 Off Road Camaro</div>
                <div>'70 Plymouth Road Runner (in purple)</div>
                <div>'70 Dodge Charger R/T (in gray)</div>
                <div>'70 Chevrolet Chevelle SS (in gray)</div>
                <div>'95 Mitsubishi Eclipse (in green)</div>
                <div>'70 Chevelle SS (in black)</div>
                <div>'61 Impala (in blue)</div>
                <div>'70 Ford Escort RS1600 (in yellow)</div>
                <div>'70 Chevelle SS Wagon (in red)</div>
                <div>'68 Dodge Charger (in orange)</div>
                <div>'70 Ford Escort RS1600 (in white)</div>
                <div>'72 Ford Gran Torino Sport (in green)</div>
                <div>'70 Chevrolet Chevelle SS Wagon (in red)</div>
                <div>'69 Ford Mustang Boss 302 (in red)</div>
                <div>'94 Toyota Supra MK IV (in white)</div>
            </div>
          </AccordionDetails>
          </Accordion>
          <h3 id="retro">Hot Wheels Retro Entertainment</h3>
          <p>Eine sehr beliebte Premium Serie, die ihren Focus auf Filme oder Videospiele legt. Fans von Batman, James Bond, Gran Tourismo, Forza und vielem mehr werden hier fündig. Die Serie wird nicht nur von Hot Wheels Enthusiasten gesammelt, sondern auch von Fans der Filme, was diese Serie besonders begehrt macht.</p>
          <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Liste aller Modelle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='' style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
              <div>'66 TV Series Batmobile (2012)</div>
              <div>The Dark Knight Batmobile (2012)</div>
              <div>KITT Knight Industries Two Thousand (2012)</div>
              <div>A-Team Van (2013)</div>
              <div>K.I.T.T. Super Pursuit Mode (2013)</div>
              <div>The Mystery Machine (2013)</div>
              <div>Ecto-1 (2013)</div>
              <div>Time Machine (Back to the Future) (2013)</div>
              <div>'55 Chevy Panel (2014)</div>
              <div>Snoopy (2014)</div>
              <div>The Homer (2014)</div>
              <div>The Jetsons Capsule Car (2014)</div>
              <div>The Flintstones Flintmobile (2014)</div>
              <div>Ghostbusters Ecto-1A (2015)</div>
              <div>Halo Warthog (2015)</div>
              <div>The Beatles Yellow Submarine (2016)</div>
              <div>The Beatles Kool Kombi (2016)</div>
              <div>The Beatles Dairy Delivery (2016)</div>
              <div>Deadpool Chimichanga Truck (2017)</div>
              <div>Guardians of the Galaxy Milano (2017)</div>
              <div>Gumball 3000 Subaru WRX STI (2018)</div>
              <div>Mario Kart Circuit Special (2018)</div>
              <div>Jurassic World Jeep (2019)</div>
              <div>Masters of the Universe Wind Raider (2019)</div>
              <div>SpongeBob SquarePants '69 Corvette (2019)</div>
              <div>The Mandalorian The Child (2020)</div>
              <div>The Beatles Yellow Submarine (2020)</div>
              <div>The Beatles Rubber Soul Morris Mini (2020)</div>
              <div>Back to the Future Time Machine (2020)</div>
              <div>The Jetsons Capsule Car (2020)</div>
              <div>The Flintstones Flintmobile (2020)</div>
              <div>The Beatles Yellow Submarine Bump Around (2021)</div>
              <div>The Beatles Rubber Soul Volkswagen T1 Panel Bus (2021)</div>
              <div>The Beatles Abbey Road Pop Culture Mix (2021)</div>
              <div>Ghostbusters Ecto-1A (2021)</div>
              <div>The Batman Batmobile (2021)</div>
              <div>The Beatles Yellow Submarine Fast Felion (2022)</div>
              <div>The Beatles Rubber Soul Rolling Thunder (2022)</div>
              <div>The Beatles Abbey Road Morris Mini (2022)</div>
            </div>
          </AccordionDetails>
          </Accordion>
          
          <h2 id="basic" className="border">Basic Serien</h2>
          <div className='row'>
            <div className='column'>
              <h3 id="bluecards">Blue Cards</h3>
              <p>Anfang der 1990 hat Mattel das Design der Karte überarbeitet und die Hauptfarbe der Karten war ab dann Blau. Die Blue Cards gab es von 1989 bis 1994 und zeichnen sich durch das blaue Karten Design aus. Es gibt 271 verschiedene Modelle aus dieser Zeit und sind bei Sammlern sehr beliebt. Da die Autos nun 30 Jahre alt sind und nur 5 Jahre produziert wurden ist es etwas schwerer Fahrzeuge in guter Kondition zufinden.</p>
            </div>
            <img src={bluecard} className="margin" alt="hot wheels car culture" style={{width: 175, marginLeft: 15, objectFit: "contain"}}/>
          </div>
          <h3 id="exclusives">Hot Wheels "Store Exclusives"</h3>
          <p>Wenn Mattel mit Einzelhändlern kooperiert, werden oft sogenannte Store Exclusives herausgegeben. Oft ist das eine neue Färbung für ein Basic Modell. Diese Store Exclusives sind sehr stark limitiert und sehr begehrt.</p>
        
          <h3 id="thunt">Treasure Hunt</h3>
          <div className='row'>
            <p>
              Seit 1995 gibt es sogenannte Treasure Hunt Version von bestimmten Hot Wheels Autos. Für jedes gewählte Modell wurden im ersten Jahr zusätzlich 10.000 Treasure Hunts produziert und seit 1996 25.000. Diese unterscheiden sich meist nur durch einen grünen Strich und dem Schriftzug "Treasure Hunt" oder "T-Hunt".
              <br /><br />In 2007 wurden die Super Tresure Hunts vorgestellt. Die Super Version von einem Auto hat ein Flammen Vinyl und Gummiräder und sind oft stark limitiert. Man kann die Super Version aber am einfachsten an der Verpackung erkennen. Bei der Super Version wird bei dem Wort Tresure Hunt das S durch ein $ ersetzt also "Trea$ure Hunt$".
              <br /><br />Seit 2013 sind die Treasure Hunts schwieriger zuerkennen. Meistens muss man nachdem niedrige Produktionszahl Logo ausschau halten. Das Logo ist eine Flamme in einem Kreis (Bild rechts).
            </p>
            <img src={rare} alt="logo treasure hunt" style={{width: 100, marginLeft: 15, objectFit: "contain"}}/>
          </div>
        </div>
        <div className='content-list-wrapper' style={{width: 240, minWidth: 240, marginLeft: 25}}>
          <Inhaltsverzeichnis content={[
            {
              id: "premium",
              text: "Premium Linie",
              type: "subtitle"
            },
            {
              id: "redline",
              text: "Redline Serie",
            },
            {
              id: "collectibles",
              text: "Collectibles Series"
            },
            {
              id: "carculture",
              text: "Car Culture"
            },  
            {
              id: "vintage",
              text: "Vintage Racing"
            }, 
            
            {
              id: "fastfurious",
              text: "Fast & Furious Premium"
            }, 
            {
              id: "retro",
              text: "Retro Entertainment"
            }, 
            {
              id: "basic",
              text: "Basic Line",
              type: "subtitle"
            },
            {
              id: "bluecards",
              text: "Blue Cards"
            }, 
            {
              id: "exclusives",
              text: 'Hot Wheels "Store Exclusives"'
            },
            {
              id: "thunt",
              text: "Treasure Hunt"
            }
          ]
          }/>
        </div>
       
      </div>
      
    </div>
    
    
    
   
  </Layout>
)

export default Ratgeber
